import React from 'react';
import UserItem from './UserItem';

const UserList = ({ list, edit }) => {
  return (
    <>
      {
        list.map((usr) => (
          <UserItem
            key={usr.id}
            item={usr}
            edit={edit}
          />
        ))
      }

    </>

  );
};

export default UserList;
