import React from 'react'
import { Button } from 'react-bootstrap';


const LogoutButton = () => {

    const handleLogout = () => {
        document.cookie = 'wdm=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        window.location.reload(true);
    };

    return (
        <Button variant="danger" onClick={handleLogout}>
            Logout
        </Button>
    );
};

export default LogoutButton;
