import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import UserLevelList from './UserLevelList';
import { useSessionContext } from '../../context/SessionContext';

const UserLevelContainer = () => {
    const [userLevels, setUserLevels] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const navigate = useNavigate();
    const { wdmToken } = useSessionContext()

    const [filters, setFilters] = useState({
        completed: '',
        locked: '',
        validWords: '',
        invalidWords: '',
        duplicateWords: '',
        wordsPerSecond: '',
        highestScore: '',
        mostValuableWordEntered: '',
        idLevel: '',
        idUser: ''
    });

    const headers = [
        { title: 'Is Complete?', filterKey: 'completed' },
        { title: 'Is Locked?', filterKey: 'locked' },
        { title: 'Valid Words', filterKey: 'validWords' },
        { title: 'Invalid Words', filterKey: 'invalidWords' },
        { title: 'Duplicate Words', filterKey: 'duplicateWords' },
        { title: 'Words per Second', filterKey: 'wordsPerSecond' },
        { title: 'Highest Score', filterKey: 'highestScore' },
        { title: 'Most Valuable word Entered', filterKey: 'mostValuableWordEntered' },
        { title: 'Level Id', filterKey: 'idLevel' },
        { title: 'User Id', filterKey: 'idUser' },
        { title: 'Edit', filterKey: '' },
    ];

    const apiUrl = process.env.REACT_APP_API_URL;

    const fetchData = () => {
        let apiUrlWithParams = `${apiUrl}/user-level?page=${currentPage}`;

        Object.keys(filters).forEach(key => {
            if (filters[key]) {
                apiUrlWithParams += `&${key}=${filters[key]}`;
            }
        });

        axios.get(apiUrlWithParams, {
            headers: {
                Authorization: `Bearer ${wdmToken}`
            }
        })
            .then(response => {
                setUserLevels(response.data.result);
                setCurrentPage(response.data.page);
                setTotalPages(response.data.totalPages);
            })
            .catch(error => {
                console.error('Error fetching levels:', error);
            });
    };

    useEffect(() => {
        fetchData();
    }, [currentPage, filters]);

    const handleFilterChange = (filter, value) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filter]: value,
        }));
        setCurrentPage(1);
    };

    const clearAllFilters = () => {
        setFilters({
            completed: '',
            locked: '',
            validWords: '',
            invalidWords: '',
            duplicateWords: '',
            wordsPerSecond: '',
            highestScore: '',
            mostValuableWordEntered: '',
            idLevel: '',
            idUser: ''
        });
        setCurrentPage(1);
    };

    const goToNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const goToPrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const edit = (id) => {
        navigate(`/user-level/update/${id}`)
    };

    return (
        <div className="container mt-4">
            <h2>User-Level</h2>

            <div className="d-flex justify-content-between mb-3">
                <button className="btn btn-secondary" onClick={clearAllFilters}>Clear All Filters</button>
            </div>

            <table className="table table-striped table-bordered custom-table">
                <thead className="thead-dark">
                    <tr>
                        {headers.map(header => (
                            <th key={header.title}>
                                {header.title}
                                {header.filterKey ? (
                                    <>
                                        {header.filterKey === 'completed' || header.filterKey === 'locked' ? (
                                            <select
                                                className="form-select form-select-sm"
                                                value={filters[header.filterKey] || ''}
                                                onChange={(e) => handleFilterChange(header.filterKey, e.target.value)}
                                            >
                                                <option value="">All</option>
                                                <option value="true">True</option>
                                                <option value="false">False</option>
                                            </select>
                                        ) : (
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                value={filters[header.filterKey]}
                                                onChange={(e) => handleFilterChange(header.filterKey, e.target.value)}
                                            />
                                        )}
                                    </>
                                ) : null}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <UserLevelList list={userLevels} edit={edit} />
                </tbody>
            </table>

            <div className="d-flex justify-content-between mt-3">
                <button className="btn btn-primary" onClick={goToPrevPage} disabled={currentPage === 1}>Prev</button>
                <button className="btn btn-primary" onClick={goToNextPage} disabled={currentPage === totalPages}>Next</button>
            </div>
            <div className="mt-3">
                Page {currentPage} of {totalPages}
            </div>


        </div>
    );
}

export default UserLevelContainer;
