import axios from 'axios';
import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useSessionContext } from '../../context/SessionContext';

const TwoFaForm = () => {
    const [code, setCode] = useState('');
    const [error, setError] = useState('');

    const { login, user } = useSessionContext()

    const apiUrl = `${process.env.REACT_APP_API_URL}`;

    const handleVerifyCode = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${apiUrl}/wcs/verify-2fa-code`, {
                token: code,
                username: user.username
            });

            const expiresAt = new Date(response.data.expiresAt);
            login(response.data.wdm, expiresAt);
            // window.location.reload()

        } catch (error) {
            setError('Code is invalid');
        }
    };

    return (
        <Form onSubmit={handleVerifyCode}>
            <Form.Group controlId="formCode">
                <Form.Label>2 Factor Authentication Code</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter your 2FA code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                />
            </Form.Group>

            {error && <p className="text-danger">{error}</p>}

            <Button variant="primary" type="submit">
                Send
            </Button>
        </Form>
    );
}

export default TwoFaForm