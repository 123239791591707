import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import { SessionProvider } from "./context/SessionContext";
import Wordamundo from "./container/Wordamundo";

function App() {

  return (
    <SessionProvider>
      <BrowserRouter>
        <Wordamundo />
      </BrowserRouter>
    </SessionProvider>
  );
}

export default App;

