import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { useSessionContext } from '../context/SessionContext';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const { twoFaToken, setUser } = useSessionContext();
    const navigate = useNavigate()


    const apiUrl = `${process.env.REACT_APP_API_URL}`;

    const handleLogin = async (e) => {

        e.preventDefault();
        try {
            const response = await axios.post(`${apiUrl}/wcs/login`, {
                username,
                password
            });

            const expiresAt = new Date(response.data.expiresAt);

            twoFaToken(response.data.wdmst, expiresAt)
            setUser({ username: username })
            navigate('/set-qr-code')

        } catch (error) {
            setError('The user and/or the password are incorrect');
        }
    };


    return (
        <Form onSubmit={handleLogin}>
            <Form.Group controlId="formUsername">
                <Form.Label>Username</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter your username or email"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
            </Form.Group>

            <Form.Group controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                    type="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </Form.Group>

            {error && <p className="text-danger">{error}</p>}

            <Button variant="primary" type="submit">
                Log in
            </Button>
        </Form>
    );
};

export default Login;
