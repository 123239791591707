import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Login from '../../pages/Login';
import LogoutButton from '../logoutButton/LogoutButton';
import { useSessionContext } from '../../context/SessionContext';

const Navbar = () => {
    const { wdmToken } = useSessionContext();
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            {wdmToken === null ? (
                <>
                    <Navigate path="/" element={<Login />} />
                </>
            ) : (
                <nav className="navbar navbar-expand-lg navbar-light bg-light justify-content-between">
                    <div className="container">
                        <Link to="/" className="text-decoration-none text-dark">
                            Wordamundo
                        </Link>
                        <button className="navbar-toggler" type="button" onClick={toggleMenu}>
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarNav">
                            <ul className="navbar-nav mx-auto">
                                <li className="nav-item">
                                    <Link to="/level" className="nav-link">
                                        Level
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/user" className="nav-link">
                                        User
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/user-level" className="nav-link">
                                        User - Level
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/language" className="nav-link">
                                        Language
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/letter-group" className="nav-link">
                                        Letter Group
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/letter-group-level" className="nav-link">
                                        Letter Group - Level
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/letter-group-word" className="nav-link">
                                        Letter Group - Word
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/stage" className="nav-link">
                                        Stage
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/word" className="nav-link">
                                        Word
                                    </Link>
                                </li>
                            </ul>
                            <LogoutButton />
                        </div>
                    </div>
                </nav>
            )}
        </>
    );
};

export default Navbar;
