import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const NewWord = () => {
    const [formData, setFormData] = useState({
        letters: '',
        idLanguage: '',
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        let { name, value, type, checked } = e.target;

        if (type === 'number' && parseInt(value) < 0) {
            value = 0;
        }

        if (value === "None") {
            value = ""
        }

        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };



    const apiUrl = process.env.REACT_APP_API_URL;

    const handleSubmit = (e) => {
        e.preventDefault();

        axios.post(`${apiUrl}/word`, formData)
            .then(response => {
                navigate('/word');
            })
            .catch(error => {
                console.error('Error adding:', error);
            });
    };

    return (
        <div className="container mt-4">
            <h2>Add New Word</h2>

            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="letters" className="form-label">Letters</label>
                    <input required type="text" className="form-control" id="letters" name="letters" value={formData.letters} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="idLanguage" className="form-label">Language ID</label>
                    <input required type="number" className="form-control" id="idLanguage" name="idLanguage" value={formData.idLanguage} onChange={handleChange} />
                </div>

                <button type="submit" className="btn btn-primary">Add Word</button>
            </form>
        </div>
    );
}

export default NewWord;
