import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const NewLanguage = () => {
    const [formData, setFormData] = useState({
        language: ''
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        let { name, value, type, checked } = e.target;

        if (type === 'number' && parseInt(value) < 0) {
            value = 0;
        }

        if (value === "None") {
            value = ""
        }

        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };



    const apiUrl = process.env.REACT_APP_API_URL;

    const handleSubmit = (e) => {
        e.preventDefault();

        axios.post(`${apiUrl}/language`, formData)
            .then(response => {
                navigate('/language');
            })
            .catch(error => {
                console.error('Error adding:', error);
            });
    };

    return (
        <div className="container mt-4">
            <h2>Add New Language</h2>

            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="language" className="form-label">Language</label>
                    <input required type="text" className="form-control" id="language" name="language" value={formData.language} onChange={handleChange} />
                </div>

                <button type="submit" className="btn btn-primary">Add Language</button>
            </form>
        </div>
    );
}

export default NewLanguage;
