import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import LetterGroupList from './LetterGroupList';
import { useSessionContext } from '../../context/SessionContext';

const LetterGroupContainer = () => {
    const [letterGroups, setLetterGroups] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const navigate = useNavigate();

    const [filters, setFilters] = useState({
        isActive: '',
        letters: '',
        idLanguage: '',
        idStage: ''
    });

    const headers = [
        { title: 'Is Active?', filterKey: 'isActive' },
        { title: 'Letters', filterKey: 'letters' },
        { title: 'Language ID', filterKey: 'idLanguage' },
        { title: 'Stage ID', filterKey: 'idStage' },
        { title: 'Edit', filterKey: '' },
    ];

    const apiUrl = process.env.REACT_APP_API_URL;
    const { wdmToken } = useSessionContext()


    const fetchData = () => {

        let apiUrlWithParams = `${apiUrl}/letter-group?page=${currentPage}`;

        Object.keys(filters).forEach(key => {
            if (filters[key]) {
                apiUrlWithParams += `&${key}=${filters[key]}`;
            }
        });

        axios.get(apiUrlWithParams, {
            headers: {
                Authorization: `Bearer ${wdmToken}`
            }
        })
            .then(response => {
                setLetterGroups(response.data.result);
                setCurrentPage(response.data.page);
                setTotalPages(response.data.totalPages);
            })
            .catch(error => {
                console.error('Error fetching:', error);
            });
    };

    useEffect(() => {
        fetchData();
    }, [currentPage, filters]);

    const handleFilterChange = (filter, value) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filter]: value,
        }));
        setCurrentPage(1);
    };

    const clearAllFilters = () => {
        setFilters({
            isActive: '',
            letters: '',
            idLanguage: '',
            idStage: ''
        });
        setCurrentPage(1);
    };

    const goToNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const goToPrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const edit = (id) => {
        navigate(`/letter-group/update/${id}`)
    };

    return (
        <div className="container mt-4">
            <h2>Letter Groups</h2>

            <div className="d-flex justify-content-between mb-3">
                <button className="btn btn-secondary" onClick={clearAllFilters}>Clear All Filters</button>
                <button className="btn btn-primary" onClick={() => navigate('/letter-group/add')}>Add New</button>
            </div>

            <table className="table table-striped table-bordered custom-table">
                <thead className="thead-dark">
                    <tr>
                        {headers.map(header => (
                            <th key={header.title}>
                                {header.title}
                                {header.filterKey ? (
                                    <>
                                        {header.filterKey === 'isActive' ? (
                                            <select
                                                className="form-select form-select-sm"
                                                value={filters[header.filterKey] || ''}
                                                onChange={(e) => handleFilterChange(header.filterKey, e.target.value)}
                                            >
                                                <option value="">All</option>
                                                <option value="true">True</option>
                                                <option value="false">False</option>


                                            </select>
                                        ) : (
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                value={filters[header.filterKey]}
                                                onChange={(e) => handleFilterChange(header.filterKey, e.target.value)}
                                            />
                                        )}
                                    </>
                                ) : null}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <LetterGroupList list={letterGroups} edit={edit} />
                </tbody>
            </table>

            <div className="d-flex justify-content-between mt-3">
                <button className="btn btn-primary" onClick={goToPrevPage} disabled={currentPage === 1}>Prev</button>
                <button className="btn btn-primary" onClick={goToNextPage} disabled={currentPage === totalPages}>Next</button>
            </div>
            <div className="mt-3">
                Page {currentPage} of {totalPages}
            </div>


        </div>
    );
}

export default LetterGroupContainer;
