import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import ConfirmationPopup from '../popUp/ConfirmationPopup';

const UpdateStage = () => {
    const { id } = useParams();
    const [formData, setFormData] = useState({
        isActive: '',
        letters: '',
        number: '',
    });
    const [loading, setLoading] = useState(true);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            axios.get(`${apiUrl}/stage/${id}`)
                .then(response => {
                    setFormData(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching:', error);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [id]);

    const handleChange = (e) => {
        let { name, value, type, checked } = e.target;

        if (type === 'number' && parseInt(value) < 0) {
            value = 0;
        }

        if (value === "None") {
            value = ""
        }

        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowConfirmation(true);
    };

    const handleConfirmUpdate = () => {
        axios.put(`${apiUrl}/stage/${id}`, formData)
            .then(response => {
                navigate('/stage');
            })
            .catch(error => {
                console.error('Error updating:', error);
            });
    };

    const handleCancelUpdate = () => {
        setShowConfirmation(false);
    };

    if (loading) {
        return <div>Loading...</div>;
    }


    return (
        <div className="container mt-4">
            <h2>Edit Stage</h2>

            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="isActive" className="form-label">Is it active?</label>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="isActive"
                        name="isActive"
                        checked={formData.isActive}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="letters" className="form-label">Letters</label>
                    <input required type="number" className="form-control" id="letters" name="letters" value={formData.letters} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="number" className="form-label">Number</label>
                    <input required type="number" className="form-control" id="number" name="number" value={formData.number} onChange={handleChange} />
                </div>
               

                <button type="submit" className="btn btn-primary">Update</button>
            </form>
            <ConfirmationPopup
                isOpen={showConfirmation}
                onConfirm={handleConfirmUpdate}
                onCancel={handleCancelUpdate}
            />
        </div>
    );
}

export default UpdateStage;
