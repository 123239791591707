import React from 'react';
import LanguageItem from './LanguageItem';

const LanguageList = ({ list, edit }) => {
  return (
    <>
      {
        list.map((item) => (
          <LanguageItem
            key={item.id}
            item={item}
            edit={edit}
          />
        ))
      }

    </>

  );
};

export default LanguageList;
