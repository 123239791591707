import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import LevelList from './LevelList';
import './level.css';
import { useSessionContext } from '../../context/SessionContext';

const LevelContainer = () => {
  const [levels, setLevels] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();
  const { wdmToken } = useSessionContext()

  const [filters, setFilters] = useState({
    isActive: '',
    requiredLetterFlag: '',
    isChill: '',
    stageId: '',
    languageId: '',
    checkCondition: '',
    requiredLetter: '',
    assignment: '',
    time: '',
    number: '',
    hint: '',
    requiredLetterPosition: '',
  });

  const headers = [
    { title: 'Is Active?', filterKey: 'isActive' },
    { title: 'Number', filterKey: 'number' },
    { title: 'Hint', filterKey: 'hint' },
    { title: 'Assignment', filterKey: 'assignment' },
    { title: 'Time', filterKey: 'time' },
    { title: 'Required letter?', filterKey: 'requiredLetterFlag' },
    { title: 'Required letter position', filterKey: 'requiredLetter' },
    { title: 'Check condition', filterKey: 'checkCondition' },
    { title: 'Is Chill?', filterKey: 'isChill' },
    { title: 'Stage ID', filterKey: 'idStage' },
    { title: 'Language ID', filterKey: 'idLanguage' },
    { title: 'Edit', filterKey: '' },
  ];

  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchData = () => {
    let apiUrlWithParams = `${apiUrl}/level?page=${currentPage}`;

    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        apiUrlWithParams += `&${key}=${filters[key]}`;
      }
    });

    axios.get(apiUrlWithParams, {
      headers: {
          Authorization: `Bearer ${wdmToken}`
      }
  })
      .then(response => {
        setLevels(response.data.result);
        setCurrentPage(response.data.page);
        setTotalPages(response.data.totalPages);
      })
      .catch(error => {
        console.error('Error fetching levels:', error);
      });
  };


  useEffect(() => {

    fetchData();
  }, [currentPage, filters]);

  const handleFilterChange = (filter, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filter]: value,
    }));
    setCurrentPage(1);
  };

  const clearAllFilters = () => {
    setFilters({
      isActive: '',
      requiredLetterFlag: '',
      isChill: '',
      stageId: '',
      languageId: '',
      checkCondition: '',
      requiredLetter: '',
      assignment: '',
      time: '',
      number: '',
      hint: '',
      requiredLetterPosition: '',
    });
    setCurrentPage(1);
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const editLevel = (id) => {
    navigate(`/level/update/${id}`)
  };

  return (
    <div className="container mt-4">
      <h2>Levels</h2>

      <div className="d-flex justify-content-between flex-wrap mb-3">
        <button className="btn btn-secondary mb-2 mb-md-0" onClick={clearAllFilters}>Clear All Filters</button>
        <button className="btn btn-primary mb-2 mb-md-0" onClick={() => navigate('/level/add')}>Add New</button>
      </div>

      <div className="table-responsive">
        <table className="table table-striped table-bordered custom-table">
          <thead className="thead-dark">
            <tr>
              {headers.map(header => (
                <th key={header.title}>
                  {header.title}
                  {header.filterKey ? (
                    <>
                      {header.filterKey === 'requiredLetterFlag' || header.filterKey === 'isActive' || header.filterKey === 'isChill' ? (
                        <select
                          className="form-select form-select-sm"
                          value={filters[header.filterKey] || ''}
                          onChange={(e) => handleFilterChange(header.filterKey, e.target.value)}
                        >
                          <option value="">All</option>
                          {header.filterKey === 'requiredLetterFlag' || header.filterKey === 'isActive' || header.filterKey === 'isChill' ? (
                            <>
                              <option value="true">True</option>
                              <option value="false">False</option>
                            </>
                          ) : header.filterKey === 'requiredLetter' ? (
                            <>
                              <option value="first">First</option>
                              <option value="last">Last</option>
                            </>
                          ) : null}
                        </select>
                      ) : (
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          value={filters[header.filterKey]}
                          onChange={(e) => handleFilterChange(header.filterKey, e.target.value)}
                        />
                      )}
                    </>
                  ) : null}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <LevelList levels={levels} editLevel={editLevel} />
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-between flex-wrap mt-3">
        <button className="btn btn-primary" onClick={goToPrevPage} disabled={currentPage === 1}>Prev</button>
        <button className="btn btn-primary" onClick={goToNextPage} disabled={currentPage === totalPages}>Next</button>
      </div>
      <div className="mt-3">
        Page {currentPage} of {totalPages}
      </div>
    </div>
  );
}

export default LevelContainer;
