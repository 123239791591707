import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSessionContext } from '../../context/SessionContext';

const NewLevel = () => {
    const { wdmToken } = useSessionContext();
    const [stages, setStages] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [formData, setFormData] = useState({
        hint: '',
        assignment: '',
        time: 60,
        requiredLetterFlag: false,
        requiredLetter: "",
        checkCondition: "",
        isChill: false,
        idStage: '',
        idLanguage: '',
        numberOfWords: 0,
        numberOfLetters: 0,
        lettersAtLeast: false,
        endIsStart: false
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        let { name, value, type, checked } = e.target;
    
        if (type === 'number' && parseInt(value) < 0) {
            value = 0;
        }
    
        if (value === "None") {
            value = ""
        }
    
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    
        generateCheckCondition(name, type === 'checkbox' ? checked : value);
    };
    

    const generateCheckCondition = (name, value) => {
        const { numberOfWords, requiredLetterFlag, requiredLetter, numberOfLetters, lettersAtLeast, endIsStart } = {
            ...formData,
            [name]: value
        };
    
        let conditions = [];
        if (numberOfWords > 0) {
            conditions.push(`words::${numberOfWords}`);
        }
    
        if (requiredLetterFlag && requiredLetter === 'first') {
            conditions.push('sameCharStart');
        } else if (requiredLetterFlag && requiredLetter === 'last') {
            if (endIsStart) {
                conditions.push('endIsStart');
            } else {
                conditions.push('sameCharEnd');
            }
        }
    
        if (numberOfLetters > 0 && !requiredLetterFlag) {
            let lettersCondition = `letters::${numberOfLetters}`;
            if (lettersAtLeast) {
                lettersCondition += '+';
            }
            conditions.push(lettersCondition);
        }
    
        if (conditions.length > 2) {
            conditions = [conditions[0], conditions[1] === 'words' ? conditions[2] : conditions[1]];
        }
    
        setFormData(prevData => ({
            ...prevData,
            checkCondition: conditions.join('::'),
        }));
    };
    

    const apiUrl = process.env.REACT_APP_API_URL;

    const getStages = async () => {
        try {
            const res = await axios.get(`${apiUrl}/stage`, {
                headers: {
                    Authorization: `Bearer ${wdmToken}`
                }
            });

            setStages(res.data.stages);
        } catch (error) {
            console.error('Error fetching stages:', error);
        }
    };

    const getLanguages = async () => {
        try {
            const res = await axios.get(`${apiUrl}/language`, {
                headers: {
                    Authorization: `Bearer ${wdmToken}`
                }
            });

            setLanguages(res.data.languages);
        } catch (error) {
            console.error('Error fetching languages:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { numberOfWords, numberOfLetters, checkCondition, idStage, idLanguage, ...dataToSend } = formData; // Exclude numberOfWords and numberOfLetters

        if (!checkCondition.includes('words::')) {
            alert("Invalid check condition. It must contain 'words::'.");
            return;
        }

        if (!idStage) {
            alert("Please select a valid stage.");
            return;
        }

        if (!idLanguage) {
            alert("Please select a valid language.");
            return;
        }

        try {
            await axios.post(`${apiUrl}/level`, { ...dataToSend, checkCondition, idStage, idLanguage }, {
                headers: {
                    Authorization: `Bearer ${wdmToken}`
                }
            });
            navigate('/level');
        } catch (error) {
            console.error('Error adding level:', error);
        }
    };

    useEffect(() => {
        getStages();
        getLanguages();
    }, []);

    return (
        <div className="container mt-4">
            <h2>Add New Level</h2>

            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="hint" className="form-label">Hint (can be left blank)</label>
                    <input type="text" className="form-control" id="hint" name="hint" value={formData.hint} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="assignment" className="form-label">Assignment</label>
                    <input required type="text" className="form-control" id="assignment" name="assignment" value={formData.assignment} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="time" className="form-label">Time</label>
                    <input required type="number" className="form-control" id="time" name="time" value={formData.time} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="numberOfWords" className="form-label">Number of Words</label>
                    <input required type="number" className="form-control" id="numberOfWords" name="numberOfWords" value={formData.numberOfWords} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="numberOfLetters" className="form-label">Number of Letters</label>
                    <input
                        required
                        type="number"
                        className="form-control"
                        id="numberOfLetters"
                        name="numberOfLetters"
                        value={formData.numberOfLetters}
                        onChange={handleChange}
                        disabled={formData.requiredLetterFlag}
                    />
                </div>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="lettersAtLeast"
                        name="lettersAtLeast"
                        checked={formData.lettersAtLeast}
                        onChange={handleChange}
                        disabled={formData.requiredLetterFlag || formData.numberOfLetters === 0}
                    />
                    <label className="form-check-label" htmlFor="lettersAtLeast">
                        At least
                    </label>
                </div>
                <div className="mb-3">
                    <label htmlFor="requiredLetterFlag" className="form-label">Is there a required letter?</label>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="requiredLetterFlag"
                        name="requiredLetterFlag"
                        checked={formData.requiredLetterFlag}
                        onChange={handleChange}
                        disabled={formData.numberOfLetters > 0}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="requiredLetter" className="form-label">Required Letter</label>
                    <select
                        className="form-control"
                        id="requiredLetter"
                        name="requiredLetter"
                        value={formData.requiredLetter}
                        onChange={handleChange}
                        disabled={!formData.requiredLetterFlag}
                    >
                        <option value="none">None</option>
                        <option value="first">First</option>
                        <option value="last">Last</option>
                    </select>
                    {formData.requiredLetter === 'last' && formData.requiredLetterFlag && (
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="endIsStart"
                                name="endIsStart"
                                checked={formData.endIsStart}
                                onChange={handleChange}
                            />
                            <label className="form-check-label" htmlFor="endIsStart">
                                Last letter needs to be the next first?
                            </label>
                        </div>
                    )}
                </div>

                <div className="mb-3">
                    <label htmlFor="isChill" className="form-label">Is it a chill level?</label>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="isChill"
                        name="isChill"
                        checked={formData.isChill}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="idStage" className="form-label">Stage</label>
                    <select
                        className="form-select"
                        id="idStage"
                        name="idStage"
                        value={formData.idStage}
                        onChange={handleChange}
                    >
                        <option value="">Select a stage</option>
                        {stages.map(stage => (
                            <option key={stage.id} value={stage.id}>{stage.letters}</option>
                        ))}
                    </select>
                </div>
                <div className="mb-3">
                    <label htmlFor="idLanguage" className="form-label">Language</label>
                    <select
                        className="form-select"
                        id="idLanguage"
                        name="idLanguage"
                        value={formData.idLanguage}
                        onChange={handleChange}
                    >
                        <option value="">Select a language</option>
                        {languages.map(language => (
                            <option key={language.id} value={language.id}>{language.language}</option>
                        ))}
                    </select>
                </div>
                <div className="mb-3">
                    <label htmlFor="checkCondition" className="form-label">Condition to check</label>
                    <input
                        type="text"
                        className="form-control"
                        id="checkCondition"
                        name="checkCondition"
                        value={formData.checkCondition}
                        readOnly
                    />
                </div>
                <button type="submit" className="btn btn-primary">Add Level</button>
            </form>
        </div>
    );
}

export default NewLevel;
