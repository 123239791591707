import React from 'react';

const LevelItem = ({ item, editLevel }) => {
    return (
        <tr>
            <td>{item.isActive}</td>
            <td>{item.number}</td>
            <td>{item.hint}</td>
            <td>{item.assignment}</td>
            <td>{item.time}</td>
            <td>{item.requiredLetterFlag}</td>
            <td>{item.requiredLetter}</td>
            <td>{item.checkCondition}</td>
            <td>{item.isChill}</td>
            <td>{item.idStage}</td>
            <td>{item.idLanguage}</td>
            <td>
                <button onClick={() => editLevel(item.id)}>Edit</button>
            </td>
        </tr>
    );
};

export default LevelItem;
