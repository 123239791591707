import React from 'react';

const UserLevelItem = ({ item, edit }) => {
    return (
        <tr>
            <td>{item.completed}</td>
            <td>{item.locked}</td>
            <td>{item.validWords}</td>
            <td>{item.invalidWords}</td>
            <td>{item.duplicateWords}</td>
            <td>{item.wordsPerSecond}</td>
            <td>{item.highestScore}</td>
            <td>{item.mostValuableWordEntered}</td>
            <td>{item.idLevel}</td>
            <td>{item.idUser}</td>
            <td>
                <button onClick={() => edit(item.id)}>Edit</button>
            </td>
        </tr>
    );
};

export default UserLevelItem;
