import React from 'react';

const LetterGroupLevelItem = ({ item, edit }) => {
    return (
        <tr>
            <td>{item.used}</td>
            <td>{item.idLetterGroup}</td>
            <td>{item.idLevel}</td>
            <td>
                <button onClick={() => edit(item.id)}>Edit</button>
            </td>
        </tr>
    );
};

export default LetterGroupLevelItem;
