import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import ConfirmationPopup from '../popUp/ConfirmationPopup';

const UpdateLevel = () => {
    const { id } = useParams();
    const [formData, setFormData] = useState({
        isActive: false,
        hint: '',
        assignment: '',
        time: '',
        requiredLetterFlag: false,
        requiredLetter: '',
        checkCondition: '',
        isChill: false,
        idStage: 1,
        idLanguage: 1,
    });
    const [loading, setLoading] = useState(true);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            axios.get(`${apiUrl}/level/${id}`)
                .then(response => {
                    setFormData(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching level:', error);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [id]);

    const handleChange = (e) => {
        let { name, value, type, checked } = e.target;

        if (type === 'number' && parseInt(value) < 0) {
            value = 0;
        }

        if (value === "None") {
            value = ""
        }

        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowConfirmation(true);
    };

    const handleConfirmUpdate = () => {
        axios.put(`${apiUrl}/level/${id}`, formData)
            .then(response => {
                navigate('/level');
            })
            .catch(error => {
                console.error('Error updating:', error);
            });
    };

    const handleCancelUpdate = () => {
        setShowConfirmation(false);
    };

    if (loading) {
        return <div>Loading...</div>;
    }


    return (
        <div className="container mt-4">
            <h2>Edit Level</h2>

            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="requiredLetterFlag" className="form-label">Is it active?</label>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="isActive"
                        name="isActive"
                        checked={formData.isActive}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="hint" className="form-label">Hint</label>
                    <input type="text" className="form-control" id="hint" name="hint" value={formData.hint} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="assignment" className="form-label">Assignment</label>
                    <input required type="text" className="form-control" id="assignment" name="assignment" value={formData.assignment} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="hint" className="form-label">Time</label>
                    <input required type="number" className="form-control" id="time" name="time" value={formData.time} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="requiredLetterFlag" className="form-label">Is there a required letter?</label>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="requiredLetterFlag"
                        name="requiredLetterFlag"
                        checked={formData.requiredLetterFlag}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="requiredLetter" className="form-label">Required Letter</label>
                    <select required
                        className="form-control"
                        id="requiredLetter"
                        name="requiredLetter"
                        value={formData.requiredLetter}
                        onChange={handleChange}
                    >
                        <option value="none">None</option>
                        <option value="first">First</option>
                        <option value="last">Last</option>
                    </select>
                </div>
                <div className="mb-3">
                    <label htmlFor="checkCondition" className="form-label">Condition to check</label>
                    <input required type="text" className="form-control" id="checkCondition" name="checkCondition" value={formData.checkCondition} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="requiredLetterFlag" className="form-label">Is it a chill level?</label>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="isChill"
                        name="isChill"
                        checked={formData.isChill}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="hint" className="form-label">Stage ID</label>
                    <input required type="number" className="form-control" id="idStage" name="idStage" value={formData.idStage} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="hint" className="form-label">Language ID</label>
                    <input required type="number" className="form-control" id="idLanguage" name="idLanguage" value={formData.idLanguage} onChange={handleChange} />
                </div>
                <button type="submit" className="btn btn-primary">Update</button>
            </form>
            <ConfirmationPopup
                isOpen={showConfirmation}
                onConfirm={handleConfirmUpdate}
                onCancel={handleCancelUpdate}
            />
        </div>
    );
}

export default UpdateLevel;
