import React from 'react';

const UserItem = ({ item, edit }) => {
    return (
        <tr>
            <td>{item.isActive}</td>
            <td>{item.email}</td>
            <td>{item.username}</td>
            <td>{item.avatar}</td>
            <td>{item.role}</td>
            <td>{item.diamonds}</td>
            <td>{item.coins}</td>
            <td>{item.validWords}</td>
            <td>{item.invalidWords}</td>
            <td>{item.duplicateWords}</td>
            <td>{item.firstLoad}</td>
            <td>{item.appleId}</td>
            <td>{item.googleId}</td>
            <td>{item.idLanguage}</td>
            <td>
                <button onClick={() => edit(item.id)}>Edit</button>
            </td>
        </tr>
    );
};

export default UserItem;
