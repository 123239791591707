import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import LetterGroupLevelList from './LetterGroupLevelList';
import { useSessionContext } from '../../context/SessionContext';

const LetterGroupLevelContainer = () => {
    const { wdmToken } = useSessionContext()
    const [letterGroupLevels, setLetterGroupLevels] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const navigate = useNavigate();

    const [filters, setFilters] = useState({
        used: '',
        idLetterGroup: '',
        idLevel: '',
    });

    const headers = [
        { title: 'Is it Used?', filterKey: 'used' },
        { title: 'Letter Group ID', filterKey: 'idLetterGroup' },
        { title: 'Level ID', filterKey: 'idLevel' },
        { title: 'Edit', filterKey: '' },
    ];

    const apiUrl = process.env.REACT_APP_API_URL;

    const fetchData = () => {
        let apiUrlWithParams = `${apiUrl}/letter-group-level?page=${currentPage}`;

        Object.keys(filters).forEach(key => {
            if (filters[key]) {
                apiUrlWithParams += `&${key}=${filters[key]}`;
            }
        });

        axios.get(apiUrlWithParams, {
            headers: {
                Authorization: `Bearer ${wdmToken}`
            }
        })
            .then(response => {
                setLetterGroupLevels(response.data.result);
                setCurrentPage(response.data.page);
                setTotalPages(response.data.totalPages);
            })
            .catch(error => {
                console.error('Error fetching:', error);
            });
    };

    useEffect(() => {
        fetchData();
    }, [currentPage, filters]);

    const handleFilterChange = (filter, value) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filter]: value,
        }));
        setCurrentPage(1);
    };

    const clearAllFilters = () => {
        setFilters({
            used: '',
            idLetterGroup: '',
            idLevel: '',
        });
        setCurrentPage(1);
    };

    const goToNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const goToPrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const edit = (id) => {
        navigate(`/letter-group-level/update/${id}`)
    };

    return (
        <div className="container mt-4">
            <h2>Letter Group-Level</h2>

            <div className="d-flex justify-content-between mb-3">
                <button className="btn btn-secondary" onClick={clearAllFilters}>Clear All Filters</button>
            </div>

            <table className="table table-striped table-bordered custom-table">
                <thead className="thead-dark">
                    <tr>
                        {headers.map(header => (
                            <th key={header.title}>
                                {header.title}
                                {header.filterKey ? (
                                    <>
                                        {header.filterKey === 'used' ? (
                                            <select
                                                className="form-select form-select-sm"
                                                value={filters[header.filterKey] || ''}
                                                onChange={(e) => handleFilterChange(header.filterKey, e.target.value)}
                                            >
                                                <option value="">All</option>
                                                <option value="true">True</option>
                                                <option value="false">False</option>
                                            </select>
                                        ) : (
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                value={filters[header.filterKey]}
                                                onChange={(e) => handleFilterChange(header.filterKey, e.target.value)}
                                            />
                                        )}
                                    </>
                                ) : null}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <LetterGroupLevelList list={letterGroupLevels} edit={edit} />
                </tbody>
            </table>

            <div className="d-flex justify-content-between mt-3">
                <button className="btn btn-primary" onClick={goToPrevPage} disabled={currentPage === 1}>Prev</button>
                <button className="btn btn-primary" onClick={goToNextPage} disabled={currentPage === totalPages}>Next</button>
            </div>
            <div className="mt-3">
                Page {currentPage} of {totalPages}
            </div>


        </div>
    );
}

export default LetterGroupLevelContainer;
