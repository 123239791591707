import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

const NotFound = () => {
    return (
        <Container>
            <Row className="justify-content-center">
                <Col md={6} className="text-center mt-5">
                    <h1>Error 404</h1>
                    <p>Page not found</p>
                    <Button href="/" variant="primary">Go Home</Button>
                </Col>
            </Row>
        </Container>
    );
};

export default NotFound;
