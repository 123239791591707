
import NewLevel from "../components/level/NewLevel";
import UpdateLevel from "../components/level/UpdateLevel";
import UserContainer from "../components/user/UserContainer";
import NewUser from "../components/user/NewUser";
import UpdateUser from "../components/user/UpdateUser";
import UserLevelContainer from "../components/userLevel/UserLevelContainer";
import UpdateUserLevel from "../components/userLevel/UserLevelUpdate";
import LanguageContainer from "../components/language/LanguageContainer";
import NewLanguage from "../components/language/NewLanguage";
import UpdateLanguage from "../components/language/UpdateLanguage";
import LetterGroupContainer from "../components/letterGroup/LetterGroupContainer";
import NewLetterGroup from "../components/letterGroup/NewLetterGroup";
import UpdateLetterGroup from "../components/letterGroup/UpdateLetterGroup";
import LetterGroupLevelContainer from "../components/letterGroupLevel/LetterGroupLevelContainer";
import UpdateLetterGroupLevel from "../components/letterGroupLevel/LetterGroupLevelUpdate";
import LetterGroupWordContainer from "../components/letterGroupWord/LetterGroupWordContainer";
import UpdateLetterGroupWord from "../components/letterGroupWord/LetterGroupWordUpdate";
import StageContainer from "../components/stage/StageContainer";
import NewStage from "../components/stage/NewStage";
import UpdateStage from "../components/stage/UpdateStage";
import WordContainer from "../components/word/WordContainer";
import NewWord from "../components/word/NewWord";
import UpdateWord from "../components/word/UpdateWord";


import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from "../pages/Home";
import Login from "../pages/Login";
import Navbar from "../components/navbar/Navbar";
import NotFound from "../components/notfound/NotFound";
import LevelContainer from "../components/level/LevelContainer";
import QrCode from "../pages/QrCode";
import { useSessionContext } from "../context/SessionContext";

const RoutesContainer = () => {
    const { wdmToken, wdmstToken } = useSessionContext()
    
    const location = useLocation()

    const key = location.pathname;

    return (
        <>
            <Navbar />
            <Routes key={key}>
                {
                    wdmToken !== null ?
                        (
                            <>
                                <Route path="/" element={<Home />} />
                                <Route path="/level" element={<LevelContainer />} />
                                <Route path="/level/add" element={<NewLevel />} />
                                <Route path="/level/update/:id" element={<UpdateLevel />} />
                                <Route path="/user" element={<UserContainer />} />
                                <Route path="/user/add" element={<NewUser />} />
                                <Route path="/user/update/:id" element={<UpdateUser />} />
                                <Route path="/user-level" element={<UserLevelContainer />} />
                                <Route path="/user-level/update/:id" element={<UpdateUserLevel />} />
                                <Route path="/language" element={<LanguageContainer />} />
                                <Route path="/language/add" element={<NewLanguage />} />
                                <Route path="/language/update/:id" element={<UpdateLanguage />} />
                                <Route path="/letter-group" element={<LetterGroupContainer />} />
                                <Route path="/letter-group/add" element={<NewLetterGroup />} />
                                <Route path="/letter-group/update/:id" element={<UpdateLetterGroup />} />
                                <Route path="/letter-group-level" element={<LetterGroupLevelContainer />} />
                                <Route path="/letter-group-level/update/:id" element={<UpdateLetterGroupLevel />} />
                                <Route path="/letter-group-word" element={<LetterGroupWordContainer />} />
                                <Route path="/letter-group-level/update/:id" element={<UpdateLetterGroupWord />} />
                                <Route path="/stage" element={<StageContainer />} />
                                <Route path="/stage/add" element={<NewStage />} />
                                <Route path="/stage/update/:id" element={<UpdateStage />} />
                                <Route path="/word" element={<WordContainer />} />
                                <Route path="/word/add" element={<NewWord />} />
                                <Route path="/word/update/:id" element={<UpdateWord />} />
                                <Route path="*" element={<NotFound />} />
                            </>
                        )
                        :
                        (
                            <>
                                {wdmstToken !== null && <Route path="/set-qr-code" element={<QrCode />} />}
                                <Route path="*" element={<Login />} />

                            </>
                        )
                }


            </Routes>
        </>
    );
}

export default RoutesContainer;
