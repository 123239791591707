import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const NewStage = () => {
    const [formData, setFormData] = useState({
        letters: '',
        number: '',
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        let { name, value, type, checked } = e.target;

        if (type === 'number' && parseInt(value) < 0) {
            value = 0;
        }

        if (value === "None") {
            value = ""
        }

        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };



    const apiUrl = process.env.REACT_APP_API_URL;

    const handleSubmit = (e) => {
        e.preventDefault();

        axios.post(`${apiUrl}/stage`, formData)
            .then(response => {
                navigate('/stage');
            })
            .catch(error => {
                console.error('Error adding:', error);
            });
    };

    return (
        <div className="container mt-4">
            <h2>Add New Stage</h2>

            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="letters" className="form-label">Letters</label>
                    <input required type="number" className="form-control" id="letters" name="letters" value={formData.letters} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="number" className="form-label">Number</label>
                    <input required type="number" className="form-control" id="number" name="number" value={formData.number} onChange={handleChange} />
                </div>

                <button type="submit" className="btn btn-primary">Add Stage</button>
            </form>
        </div>
    );
}

export default NewStage;
