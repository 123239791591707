import React from 'react';

const WordItem = ({ item, edit }) => {
    return (
        <tr>
            <td>{item.isActive}</td>
            <td>{item.letters}</td>
            <td>{item.idLanguage}</td>
            <td>
                <button onClick={() => edit(item.id)}>Edit</button>
            </td>
        </tr>
    );
};

export default WordItem;
