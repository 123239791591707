import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const NewUser = () => {
    const [formData, setFormData] = useState({
        email: '',
        username: '',
        avatar: '',
        appleId: '',
        googleId: '',
        idLanguage: '',
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        let { name, value, type, checked } = e.target;

        if (type === 'number' && parseInt(value) < 0) {
            value = 0;
        }

        if (value === "None") {
            value = ""
        }

        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };



    const apiUrl = process.env.REACT_APP_API_URL;

    const handleSubmit = (e) => {
        e.preventDefault();

        axios.post(`${apiUrl}/user`, formData)
            .then(response => {
                navigate('/user');
            })
            .catch(error => {
                console.error('Error adding user:', error);
            });
    };

    return (
        <div className="container mt-4">
            <h2>Add New User</h2>

            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input required type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="username" className="form-label">Username</label>
                    <input required type="text" className="form-control" id="username" name="username" value={formData.username} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="hint" className="form-label">Avatar</label>
                    <input required type="text" className="form-control" id="avatar" name="avatar" value={formData.avatar} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="hint" className="form-label">Apple Id</label>
                    <input type="text" className="form-control" id="appleId" name="appleId" value={formData.appleId} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="hint" className="form-label">Google Id</label>
                    <input type="text" className="form-control" id="googleId" name="googleId" value={formData.googleId} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="hint" className="form-label">Language Id</label>
                    <input required type="text" className="form-control" id="idLanguage" name="idLanguage" value={formData.idLanguage} onChange={handleChange} />
                </div>


                <button type="submit" className="btn btn-primary">Add User</button>
            </form>
        </div>
    );
}

export default NewUser;
