import React from 'react';
import StageItem from './StageItem';

const StageList = ({ list, edit }) => {
  return (
    <>
      {
        list.map((item) => (
          <StageItem
            key={item.id}
            item={item}
            edit={edit}
          />
        ))
      }

    </>

  );
};

export default StageList;
