import React from 'react';
import LetterGroupWordItem from './LetterGroupWordItem';

const LetterGroupWordList = ({ list, edit }) => {
  return (
    <>
      {
        list.map((itm) => (
          <LetterGroupWordItem
            key={itm.id}
            item={itm}
            edit={edit}
          />
        ))
      }

    </>

  );
};

export default LetterGroupWordList;
