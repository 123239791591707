import React from 'react';
import LetterGroupItem from './LetterGroupItem';

const LetterGroupList = ({ list, edit }) => {
  return (
    <>
      {
        list.map((item) => (
          <LetterGroupItem
            key={item.id}
            item={item}
            edit={edit}
          />
        ))
      }

    </>

  );
};

export default LetterGroupList;
