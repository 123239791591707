import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TwoFaForm from '../components/2faForm/TwoFaForm';
import { useSessionContext } from '../context/SessionContext';

const QrCode = ({ username }) => {
    const [qrCodeUrl, setQrCodeUrl] = useState("");
    const [loading, setLoading] = useState(true); // Estado de carga

    const { wdmstToken } = useSessionContext()
    const apiUrl = `${process.env.REACT_APP_API_URL}`;

    const setQrCode = async () => {
        try {
            const response = await axios.get(`${apiUrl}/wcs/set-qrcode`, {
                withCredentials: true
            });
            if (response.data.qrCodeUrl && response.data.qrCodeUrl !== "QR_CODE_ADDED") {
                setQrCodeUrl(prevUrl => prevUrl || response.data.qrCodeUrl); // Asignar el valor solo si no existe uno previo
            }
        } catch (error) {
            console.error("Error fetching:", error);
        } finally {
            setLoading(false); // Cambiar el estado de carga a falso cuando se complete la solicitud
        }
    }

    useEffect(() => {
        if (wdmstToken !== null) {
            setQrCode();
        }
    }, []);

    return (
        <div>
            <div>Please scan this QR code with your authentication app and add the code provided from the app below</div>
            {loading ? (
                <p>Loading...</p> // Mostrar un indicador de carga mientras se espera la respuesta del backend
            ) : (
                <>
                    {qrCodeUrl && <img src={qrCodeUrl} alt="QR Code" />}
                    <TwoFaForm username={username} />
                </>
            )}
        </div>
    );
}

export default QrCode;
