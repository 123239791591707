import React from 'react';
import LevelItem from './LevelItem';

const LevelList = ({ levels, editLevel }) => {
  return (
    <>
      {
        levels.map((level) => (
          <LevelItem
            key={level.id}
            item={level}
            editLevel={editLevel}
          />
        ))
      }

    </>

  );
};

export default LevelList;
